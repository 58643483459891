// URL REST API
export const URL = process.env.REACT_APP_API.replace(/\s/g, '');
export const WS = process.env.REACT_APP_WS.replace(/\s/g, '');
export const URL_AUTH = URL.replace('/api', '');
export const NEW_EDITOR_JS = false;

// Common environment vars
export const PUBLIC_URL = process.env.PUBLIC_URL;

//Модуль EntryPage
export const SET_PHONE = 'SET_PHONE';
export const SET_PASSWORD = 'SET_PASSWORD';

//Скрыть/раскрыть панель меню
export const MOBILE_MENU_VIEW = 'MOBILE_MENU_VIEW';

//Скрыть панель меню
export const MOBILE_MENU_VIEW_CLOSE = 'MOBILE_MENU_VIEW_CLOSE';

//Мобильная строка поиска в шапке
export const SEARCH_PANEL_VIEW = 'SEARCH_PANEL_VIEW';

//Скрыть мобильную строку поиска в шапке
export const SEARCH_PANEL_VIEW_CLOSE = 'SEARCH_PANEL_VIEW_CLOSE';

//Верхнее меню
export const TOP_MENU_VIEW = 'TOP_MENU_VIEW';

//Меню групп в адаптиве
export const MOBILE_GROUP_MENU = 'MOBILE_GROUP_MENU';

//Скрыть меню групп в адаптиве
export const MOBILE_GROUP_MENU_CLOSE = 'MOBILE_GROUP_MENU_CLOSE';

//Шторка фильтров вакансий в адаптиве
export const VACANCIES_FILTERS_OPEN = 'VACANCIES_FILTERS_OPEN';

//Скрыть шторку фильтров вакансий в адаптиве
export const VACANCIES_FILTERS_CLOSE = 'VACANCIES_FILTERS_CLOSE';

//Глобальный поиск получить данные
export const GLOBAL_SEARCH = 'GLOBAL_SEARCH';

//Глобальный поиск удалить данные
export const GLOBAL_SEARCH_CLEAR = 'GLOBAL_SEARCH_CLEAR';

//Получение данных о пользователе
export const SET_SHORT_USER_INFO = 'SET_SHORT_USER_INFO';
export const SET_FULL_USER_INFO = 'SET_FULL_USER_INFO';

//Получение количества уведомлений пользователя
export const SET_USERS_NOTIFICATIONS = 'SET_USERS_NOTIFICATIONS';

//Обновить информацию о пользователе
export const UPDATE_FULL_USER_INFO = 'UPDATE_FULL_USER_INFO';

//Обновить изображение профиля
export const UPDATE_USER_IMAGE = 'UPDATE_USER_IMAGE';

//Получение списка групп
export const SET_GROUP_LIST = 'SET_GROUP_LIST';

//Получение списка групп пользователя
export const SET_GROUP_USER_LIST = 'SET_GROUP_USER_LIST';

//Получение информации о группе
export const SET_GROUP_INFO = 'SET_GROUP_INFO';

//Очистить информацию о группе
export const CLEAR_GROUP_INFO = 'CLEAR_GROUP_INFO';

//Получение постов группы
export const SET_GROUP_POSTS = 'SET_GROUP_POSTS';

//Получение избранных постов
export const SET_FAVORITE_POSTS = 'SET_FAVORITE_POSTS';

//Получение постов для главной
export const SET_MAIN_PAGE_POSTS = 'SET_MAIN_PAGE_POSTS';

//Получить контент отдельного поста в модалке
export const GET_POST_PAGE_CONTENT = 'GET_POST_PAGE_CONTENT';

//Очистить контент отдельного поста в модалке
export const CLEAR_POST_PAGE_CONTENT = 'CLEAR_POST_PAGE_CONTENT';

//Добавление нового поста
export const ADD_GROUP_POSTS = 'ADD_GROUP_POSTS';

//Удаление поста
export const DELETE_GROUP_POSTS = 'DELETE_GROUP_POSTS';

//Обновление поста
export const UPDATE_GROUP_POSTS = 'UPDATE_GROUP_POSTS';

//Обновление поста
export const UPDATE_GROUP_POSTS_NEW = 'UPDATE_GROUP_POSTS_NEW';

//Восстановление поста
export const RECOVER_GROUP_POSTS = 'RECOVER_GROUP_POSTS';

//Добавление постов при скроле
export const SCROLL_GROUP_POSTS = 'SCROLL_GROUP_POSTS';

//Обновить опрос при голосовании
export const VOTE_IN_POLL = 'VOTE_IN_POLL';

//Обновление обложки группы
export const UPDATE_GROUP_COVER = 'UPDATE_GROUP_COVER';

//Лайк поста
export const POST_LIKE = 'POST_LIKE';

//Увеличить просмотр поста
export const POST_VIEW = 'POST_VIEW';

//Обновить состояние избранного
export const POST_FAVORITE = 'POST_FAVORITE';

//Редактирование группы
export const EDIT_GROUP = 'EDIT_GROUP';

//Обновление изображения группы
export const UPDATE_GROUP_IMG = 'UPDATE_GROUP_IMG';

//Удалить группу
export const DELETE_GROUP = 'DELETE_GROUP';

//Установить значения уведомления
export const SET_NOTIFICATION = 'SET_NOTIFICATION';

//Подписаться на группу
export const SUBSCRIBE_GROUP = 'SUBSCRIBE_GROUP';

//Покинуть группу
export const UNSUBSCRIBE_GROUP = 'UNSUBSCRIBE_GROUP';

//Вермя отображения уведомления
export const TIME_NOTIFICATION = 3000;

//Путь в роутере
export const ROUTER_PATH = '/';
//export const ROUTER_PATH = "/maxiportal";

//Переключение форм на странице вакансии
export const VACANCY_SWITCH = 'VACANCY_SWITCH';

//Два значения необходиые для того чтобы показать весь текст
export const TEXT_N = 250;
export const TEXT_M = 450;

//Показывать приветственное сообщение
export const WELCOME_MESSAGE = 'WELCOME_MESSAGE';

//Количество выдаваемых ответов при поиске
export const COUNT_SEARCH = 10;

//Количество выдаваемых ответов при глобальном поиске
export const COUNT_SEARCH_GLOBAL = 10;

//Установить список категорий (клуб)
export const GET_CLUB_CATEGORIES_LIST = 'GET_CLUB_CATEGORIES_LIST';

//Добавить категорию в список (клуб)
export const ADD_CLUB_CATEGORIES_LIST = 'ADD_CLUB_CATEGORIES_LIST';

//Удалить категорию (клуб)
export const DELETE_CLUB_CATEGORIES_LIST = 'DELETE_CLUB_CATEGORIES_LIST';

//Обновить категорию в списке (клуб)
export const UPDATE_CLUB_CATEGORIES_LIST = 'UPDATE_CLUB_CATEGORIES_LIST';

//Установить список партнеров (клуб)
export const GET_CLUB_PARTNERS_LIST = 'GET_CLUB_PARTNERS_LIST';

//Установить список найденных партнеров (клуб)
export const GET_CLUB_SEARCH_PARTNERS_LIST = 'GET_CLUB_SEARCH_PARTNERS_LIST';

//Установить список партнеров определенного города (клуб)
export const SET_CLUB_CITYS_PARTNERS_LIST = 'SET_CLUB_CITYS_PARTNERS_LIST';

//Добавить партнера в список (клуб)
export const ADD_CLUB_PARTNERS_LIST = 'ADD_CLUB_PARTNERS_LIST';

//Удалить партнера (клуб)
export const DELETE_CLUB_PARTNERS_LIST = 'DELETE_CLUB_PARTNERS_LIST';

//Обновить партнера в списке (клуб)
export const UPDATE_CLUB_PARTNERS_LIST = 'UPDATE_CLUB_PARTNERS_LIST';

//Установить список городов (клуб)
export const SET_CLUB_CITIES_LIST = 'SET_CLUB_CITIES_LIST';

//Установить идентификатор выбранного города
export const SET_CLUB_SELECTED_CITY = 'SET_CLUB_SELECTED_CITY';

//Установить список городов МаксиКлуба с партнерами
export const SET_CLUB_FILTERS_CITIES_LIST = 'SET_CLUB_FILTERS_CITIES_LIST';

//Максимальный размер фотографии в байтах
export const MAX_SIZE_IMAGE = 20971520;

//Число записей при загрузке ленты
export const PAGE_SIZE = 15;

//Переключение конента в зависимости от года на странице истории
export const HISTORY_SWITCH = 'HISTORY_SWITCH';

//Строка для поиска в контактах
export const SEARCH_TEXT_CONTACTS = 'SEARCH_TEXT_CONTACTS';

//Получение списка пользователей с определенной ролью
export const SET_ROLES_LIST = 'SET_ROLES_LIST';

//Добавление нового администратора раздела
export const ADD_NEW_ADMIN = 'ADD_NEW_ADMIN';

//Удаление нового администратора раздела
export const DELETE_ADMIN = 'DELETE_ADMIN';

//Получить список досок почета
export const WALK_FAME_LIST = 'WALK_FAME_LIST';

//Получить список досок почета не опубликованных
export const WALK_FAME_LIST_NO_PUBLICK = 'WALK_FAME_LIST_NO_PUBLICK';

//Получить информацию о доске почета
export const WALK_FAME_INFO = 'WALK_FAME_INFO';

//Получить список сотрудников в доске почета
export const WALK_FAME_USERS = 'WALK_FAME_USERS';

//добавить сотрудника
export const WALK_FAME_ADD_USERS = 'WALK_FAME_ADD_USERS';

//Удалить сотрудника
export const WALK_FAME_DELETE_USERS = 'WALK_FAME_DELETE_USERS';

//Получить фотоальбом доски почета
export const WALK_FAME_PHOTO = 'WALK_FAME_PHOTO';

//Добавить фотографию в альбом
export const WALK_FAME_PHOTO_ADD = 'WALK_FAME_PHOTO_ADD';

//Удалить фотографию
export const WALK_FAME_PHOTO_DELETE = 'WALK_FAME_PHOTO_DELETE';

//Обновить фотографию
export const WALK_FAME_PHOTO_UPDATE = 'WALK_FAME_PHOTO_UPDATE';

//Логи контактов для юристов
export const PEOPLE_LOG_COUNT = 50;

//Количество записей назначений на странице
export const APPOINTMENTS_COUNT = 25;

//Получение списка комментариев к посту
export const GET_COMMENTS_LIST = 'GET_COMMENTS_LIST';

//Получение количества комментариев поста
export const COMMENTS_COUNT = 'COMMENTS_COUNT';

//Удаление комментария
export const DELETE_COMMENT = 'DELETE_COMMENT';

//Восстановление комментария
export const RECOVER_COMMENT = 'RECOVER_COMMENT';

//Создание комментария
export const ADD_COMMENT = 'ADD_COMMENT';

//Добавление комментария
export const COMMENTS_COUNT_ADD = 'COMMENTS_COUNT_ADD';

//Удаление комментария
export const COMMENTS_COUNT_REMOVE = 'COMMENTS_COUNT_REMOVE';

//Глобальный флаг редактирования комментариев
export const COMMENTS_EDIT_STATUS = 'COMMENTS_EDIT_STATUS';

//Обновление комментария при редактировании
export const UPDATE_COMMENT = 'UPDATE_COMMENT';

//Скрытие комментариев
export const HIDE_COMMENTS = 'HIDE_COMMENTS';

//Установить/снять лайк комментария
export const ADD_OR_REMOVE_COMMENTS_LIKE = 'ADD_OR_REMOVE_COMMENTS_LIKE';

//Получить список фотоальбомов в группе
export const GET_PHOTO_ALBUM_LIST = 'GET_PHOTO_ALBUM_LIST';

//Получить список фотоальбомов в группе
export const GET_PHOTO_ALBUM_PHOTO_LIST = 'GET_PHOTO_ALBUM_PHOTO_LIST';

//Загрузить фотографию
export const ADD_PHOTO_ALBUM_PHOTO = 'ADD_PHOTO_ALBUM_PHOTO';

//Удалить фотографию
export const DELETE_PHOTO_ALBUM_PHOTO = 'DELETE_PHOTO_ALBUM_PHOTO';

//Обновить описание фотографии
export const UPDATE_PHOTO_ALBUM_DESCRIPTION = 'UPDATE_PHOTO_ALBUM_DESCRIPTION';

//Получить список компетенций
export const GET_ALL_COMPETENCES = 'GET_ALL_COMPETENCES';

//Добавить новую компетенцию
export const COMPETENCES_ADD = 'COMPETENCES_ADD';

//Обновить компетенцию
export const COMPETENCES_UPDATE = 'COMPETENCES_UPDATE';

//Удалить компетенцию
export const COMPETENCES_DELETE = 'COMPETENCES_DELETE';

//Изменить сортировку групп
export const SORT_GROUP = 'SORT_GROUP';

//Получить список стикеров
export const GET_STICKERS_LIST = 'GET_STICKERS_LIST';

//Добавить новый стикер
export const ADD_STICKERS = 'ADD_STICKERS';

//Удалить стикер
export const DELETE_STICKERS = 'DELETE_STICKERS';

//Получить список уведомлений в шапке
export const SET_LAST_NOTIFICATIONS = 'SET_LAST_NOTIFICATIONS';

//Очистить список уведомлений в шапке
export const CLEAR_LAST_NOTIFICATIONS = 'CLEAR_LAST_NOTIFICATIONS';

//Установить флаг загрузки уведомлений в шапке
export const SET_IS_LOADING_NOTIFICATIONS = 'SET_IS_LOADING_NOTIFICATIONS';

//Просмотреть уведомление
export const VIEW_NOTIFICATION = 'VIEW_NOTIFICATION';

//Просмотреть все уведомление
export const VIEW_ALL_NOTIFICATION = 'VIEW_ALL_NOTIFICATION';

//Установить список уведомлений в профиле
export const SET_NOTIFICATIONS_IN_PROFILE = 'SET_NOTIFICATIONS_IN_PROFILE';

//Получить список уведомлений в профиле
export const FETCH_NOTIFICATIONS_IN_PROFILE = 'FETCH_NOTIFICATIONS_IN_PROFILE';

//Тоггл загрузки уведомлений в профиле
export const FETCH_NOTIFICATIONS_IN_PROFILE_SUCCESS = 'FETCH_NOTIFICATIONS_IN_PROFILE_SUCCESS';

//Тоггл состояния последней страницы уведомлений в профиле
export const TOOGLE_LAST_PAGE_IN_NOTIFICATIONS_IN_PROFILE =
	'TOOGLE_LAST_PAGE_IN_NOTIFICATIONS_IN_PROFILE';

//Очистить список уведомлений в профиле
export const CLEAR_NOTIFICATIONS_IN_PROFILE = 'CLEAR_NOTIFICATIONS_IN_PROFILE';

//Получить инфу по конкретной заявке
export const SET_CLAIM_ITEM_INFO = 'SET_CLAIM_ITEM_INFO';

//Очистить инфу по конкретной заявке
export const CLEAR_CLAIM_ITEM_INFO = 'CLEAR_CLAIM_ITEM_INFO';

//Получить заявки в админке
export const GET_CLAIM_IN_ADMIN = 'GET_CLAIM_IN_ADMIN';

//Получить инфу по конкретному запросу
export const SET_APPROVEMENT_INFO = 'SET_APPROVEMENT_INFO';

//Очистить инфу по конкретному запросу
export const CLEAR_APPROVEMENT_INFO = 'CLEAR_APPROVEMENT_INFO';

//Получить историю запроса
export const SET_APPROVEMENT_HISTORY = 'SET_APPROVEMENT_HISTORY';

//Очистить историю запроса
export const CLEAR_APPROVEMENT_HISTORY = 'CLEAR_APPROVEMENT_HISTORY';

//Очистить список заявок в админке
export const CLEAR_CLAIM_IN_ADMIN = 'CLEAR_CLAIM_IN_ADMIN';

//Отклонить заявку из уведомления
export const REJECT_NOTIFICATION = 'REJECT_NOTIFICATION';

//Обновить количество уведомлений
export const UPDATE_NTF_COUNT = 'UPDATE_NTF_COUNT';

//Получить список предстоящих и текущих мероприятий
export const SET_ACTIVITY_OPEN = 'SET_ACTIVITY_OPEN';

//Получить список прошедших мероприятий
export const SET_ACTIVITY_CLOSE = 'SET_ACTIVITY_CLOSE';

//Получить информацию о мероприятии
export const SET_ACTIVITY_VIEW = 'SET_ACTIVITY_VIEW';

//Удалить информацию о мероприятии
export const CLEAR_ACTIVITY_VIEW = 'CLEAR_ACTIVITY_VIEW';

//Получить список участников мероприятия
export const SET_ACTIVITY_USERS = 'SET_ACTIVITY_USERS';

//Очистить список участников мероприятия
export const CLEAR_ACTIVITY_USERS = 'CLEAR_ACTIVITY_USERS';

//получить список завершенных мероприятий
export const FETCH_CLOSE_ACTIVITY_STOP = 'FETCH_CLOSE_ACTIVITY_STOP';

//Установить список поощрений пользователя
export const GET_USER_STIMULATION_LIST = 'GET_USER_STIMULATION_LIST';

//Установить список статей в группе
export const SET_ARTICLE = 'SET_ARTICLE';

//Получить список статей в группе
export const FETCH_ARTICLE = 'FETCH_ARTICLE';

//Тоггл загрузки списка статей в группе
export const FETCH_ARTICLE_SUCCESS = 'FETCH_ARTICLE_SUCCESS';

//Установить список статей в группе
export const TOOGLE_LAST_PAGE_IN_ARTICLE = 'TOOGLE_LAST_PAGE_IN_ARTICLE';

//Добавить статью в опубликованные
export const ADD_PUBLISH_ARTICLE = 'ADD_PUBLISH_ARTICLE';

//Добавить статью в черновики
export const ADD_DRAFT_ARTICLE = 'ADD_DRAFT_ARTICLE';

//Очистить список статей в группе
export const CLEAR_ARTICLE = 'CLEAR_ARTICLE';

//Перенос статьи из черовника в опубликованные
export const ARTICLE_DRAFT_TO_PUBLISH = 'ARTICLE_DRAFT_TO_PUBLISH';

//Получить статью для просмотра
export const SET_ARTICLE_VIEW = 'SET_ARTICLE_VIEW';

//Удалить статью для просмотра
export const CLEAR_ARTICLE_VIEW = 'CLEAR_ARTICLE_VIEW';

//Ошибка при получении  статью для просмотра
export const ERROR_ARTICLE_VIEW = 'ERROR_ARTICLE_VIEW';

//Получить статью для редактирования
export const SET_ARTICLE_EDIT = 'SET_ARTICLE_EDIT';
export const CLEAR_ARTICLE_EDIT = 'CLEAR_ARTICLE_EDIT';
export const DELETE_ARTICLE = 'DELETE_ARTICLE';
export const EDIT_ARTICLE = 'EDIT_ARTICLE';

//Максимальный размер загружаемого видео
export const MAX_SIZE_UPLOAD_VIDEO = 524288000;

//Список всех городов присутствия "Макси"
export const ALL_CITIES_LIST = 'ALL_CITIES_LIST';

//Установить список вакансий
export const GET_VACANCIES_LIST = 'GET_VACANCIES_LIST';

//Обновить вакансию
export const VACANCY_INFO = 'VACANCY_INFO';

//Параметры фильтра списка вакансий
export const VACANCIES_LIST_PARAMS = 'VACANCIES_LIST_PARAMS';

//Обновить параметры фильтра списка вакансий
export const VACANCIES_LIST_PARAMS_UPDATE = 'VACANCIES_LIST_PARAMS_UPDATE';

//Установить список городов с вакансиями
export const VACANCIES_CITIES_LIST = 'VACANCIES_CITIES_LIST';

//Количество вакансий на странице
export const VACANCIES_COUNT_ON_PAGE = 5;

//Количество символов в дескрипшне вакансии
export const DESCRIPTION_COUNT = 250;

//Количество получаемых "спасибо"
export const THANKS_COUNT = 13;

//Получить список "спасибо"
export const SET_THANKS = 'SET_THANKS';

//Получить список всех компетенций для "спасибо"
export const THANKS_GET_COMPETENCES = 'THANKS_GET_COMPETENCES';

//Получить список определений в глоссарии
export const SET_GLOSSARY_LIST = 'SET_GLOSSARY_LIST';

//Очистить список опредлений
export const CLEAR_GLOSSARY_LIST = 'CLEAR_GLOSSARY_LIST';

//Добавить опредление в глоссарий
export const ADD_GLOSSARY_ITEM = 'ADD_GLOSSARY_ITEM';

//Опубликовать определение
export const PUBLISH_GLOSSARY_ITEM = 'PUBLISH_GLOSSARY_ITEM';

//Удалить определение
export const DELETE_GLOSSARY_ITEM = 'DELETE_GLOSSARY_ITEM';

//Путь к сокету для загрузки файлов
export const WS_FILE_UPLOAD_SOCKET = `wss://${process.env.REACT_APP_WS.replace(
	/\s/g,
	''
)}/large-file/chunk`;

//Глобальный поиск
export const SET_SEARCH_RESULT = 'SET_SEARCH_RESULT';
export const SET_SEARCH_RESULT_CATEGORY = 'SET_SEARCH_RESULT_CATEGORY';
export const CLEAR_SEARCH_RESULT = 'CLEAR_SEARCH_RESULT';
export const CLEAR_SEARCH_RESULT_PAGE = 'CLEAR_SEARCH_RESULT_PAGE';
export const PUSH_SEARCH_RESULT_CATEGORY = 'PUSH_SEARCH_RESULT_CATEGORY';

//Информация о разделе FAQ
export const SET_FAQ_CAT_INFO = 'SET_FAQ_CAT_INFO';

//Список разделов FAQ
export const SET_FAQ_CATS_LIST = 'SET_FAQ_CATS_LIST';

//Количество вопросов на странице раздела FAQ
export const FAQ_QUESTIONS_COUNT = 50;

//Добавить вопрос в раздел FAQ
export const ADD_FAQ_QUESTION = 'ADD_FAQ_QUESTION';

//Информация о списке вопросов раздела FAQ
export const SET_FAQ_QUESTIONS_LIST = 'SET_FAQ_QUESTIONS_LIST';

//Информация о списке вопросов раздела FAQ
export const SET_FAQ_UPDATE_CAT_PARAM = 'SET_FAQ_UPDATE_CAT_PARAM';

//Обновить вопрос в разделе FAQ
export const UPDATE_FAQ_QUESTION = 'UPDATE_FAQ_QUESTION';

//Удалить вопрос из раздела FAQ
export const DELETE_FAQ_QUESTION = 'DELETE_FAQ_QUESTION';

//Восстановить удаленный вопрос из раздела FAQ
export const RECOVER_FAQ_QUESTION = 'RECOVER_FAQ_QUESTION';

//Открыть / закрыть меню в шапке
export const TOOGLE_HEAD_MENU = 'TOOGLE_HEAD_MENU';
export const CLOSE_HEAD_MENU = 'CLOSE_HEAD_MENU';

//Тоггла открытия/закрытия уведомлений в шапке
export const TOOGLE_HEAD_NOTIFICATIONS = 'TOOGLE_HEAD_NOTIFICATIONS';

//Список найденных единиц для выдачи прав
export const GET_SEARCHING_RIGHTS_ITEMS = 'GET_SEARCHING_RIGHTS_ITEMS';

//Очистить список найденных единиц для выдачи прав
export const CLEAR_SEARCHING_RIGHTS_ITEMS = 'CLEAR_SEARCHING_RIGHTS_ITEMS';

export const REQUEST_FEED_POSTS = 'REQUEST_FEED_POSTS';
export const RECEIVE_FEED_POSTS = 'RECEIVE_FEED_POSTS';
export const RECEIVE_NEXT_FEED_POSTS = 'RECEIVE_NEXT_FEED_POSTS';
export const RECEIVE_PREV_FEED_POSTS = 'RECEIVE_PREV_FEED_POSTS';
export const CLEAR_FEED_POSTS = 'CLEAR_FEED_POSTS';
export const REQUEST_FEED_POSTS_FAILD = 'REQUEST_FEED_POSTS_FAILD';
export const SET_FEED_CURRENT_PAGE = 'SET_FEED_CURRENT_PAGE';
export const SET_FEED_LAST_PAGE = 'SET_FEED_LAST_PAGE';
export const SET_FEED_IS_LOADING_POSTS = 'SET_FEED_IS_LOADING_POSTS';
export const SET_FEED_LAST_POST_ID = 'SET_FEED_LAST_POST_ID';

export const RECEIVE_GROUPS_POSTS = 'RECEIVE_GROUPS_POSTS';
export const REQUEST_GROUPS_POSTS = 'REQUEST_GROUPS_POSTS';
export const RECEIVE_NEXT_GROUPS_POSTS = 'RECEIVE_NEXT_GROUPS_POSTS';
export const RECEIVE_PREV_GROUPS_POSTS = 'RECEIVE_PREV_GROUPS_POSTS';
export const CURRENT_PAGE_GROUPS = 'CURRENT_PAGE_GROUPS';
export const LAST_PAGE_GROUPS = 'LAST_PAGE_GROUPS';
export const CLEAR_GROUPS_POSTS = 'CLEAR_GROUPS_POSTS';
export const CREATE_GROUPS_POST = 'CREATE_GROUPS_POST';
export const DELETE_GROUPS_POST = 'DELETE_GROUPS_POST';

export const RECEIVE_GROUPS_SUGGESTED_POSTS = 'RECEIVE_GROUPS_SUGGESTED_POSTS';
export const REQUEST_GROUPS_SUGGESTED_POSTS = 'REQUEST_GROUPS_SUGGESTED_POSTS';
export const RECEIVE_NEXT_GROUPS_SUGGESTED_POSTS = 'RECEIVE_NEXT_GROUPS_SUGGESTED_POSTS';
export const RECEIVE_PREV_GROUPS_SUGGESTED_POSTS = 'RECEIVE_PREV_GROUPS_SUGGESTED_POSTS';
export const CURRENT_PAGE_SUGGESTED_GROUPS = 'CURRENT_PAGE_SUGGESTED_GROUPS';
export const LAST_PAGE_SUGGESTED_GROUPS = 'LAST_PAGE_SUGGESTED_GROUPS';
export const CLEAR_GROUPS_SUGGESTED_POSTS = 'CLEAR_GROUPS_SUGGESTED_POSTS';
export const CREATE_GROUPS_SUGGESTED_POST = 'CREATE_GROUPS_SUGGESTED_POST';
export const DELETE_GROUPS_SUGGESTED_POST = 'DELETE_GROUPS_SUGGESTED_POST';

export const RECEIVE_GROUPS_DEFERRED_POSTS = 'RECEIVE_GROUPS_DEFERRED_POSTS';
export const REQUEST_GROUPS_DEFERRED_POSTS = 'REQUEST_GROUPS_DEFERRED_POSTS';
export const CURRENT_PAGE_DEFERRED_GROUPS = 'CURRENT_PAGE_DEFERRED_GROUPS';
export const LAST_PAGE_DEFERRED_GROUPS = 'LAST_PAGE_DEFERRED_GROUPS';
export const CLEAR_GROUPS_DEFERRED_POSTS = 'CLEAR_GROUPS_DEFERRED_POSTS';
export const CREATE_GROUPS_DEFERRED_POST = 'CREATE_GROUPS_DEFERRED_POST';
export const UPDATE_GROUPS_DEFERRED_POST = 'UPDATE_GROUPS_DEFERRED_POST';
export const DELETE_GROUPS_DEFERRED_POST = 'DELETE_GROUPS_DEFERRED_POST';

//Тоггл режима редактирования гида по компании
export const GUIDE_EDIT_MODE = 'GUIDE_EDIT_MODE';

//Перейти в режим редактирования гида по компании
export const GUIDE_EDIT_MODE_ON = 'GUIDE_EDIT_MODE_ON';

//Получить список категорий
export const GET_TOP_CATEGORIES_LIST = 'GET_TOP_CATEGORIES_LIST';
export const GET_CHILD_CATEGORIES_LIST = 'GET_CHILD_CATEGORIES_LIST';

//Создать категорию в гиде
export const ADD_GUIDE_CATEGORY = 'ADD_GUIDE_CATEGORY';

//Информация о категории
export const SET_GUIDE_CATEGORY_INFO = 'SET_GUIDE_CATEGORY_INFO';

//Очистить информацию о категории
export const CLEAR_GUIDE_CATEGORY_INFO = 'CLEAR_GUIDE_CATEGORY_INFO';

//Установить родительскую категорию
export const SET_PARENT_CATEGORY = 'SET_PARENT_CATEGORY';

//Обновить инфу о категории
export const UPDATE_GUIDE_CATEGORY_INFO = 'UPDATE_GUIDE_CATEGORY_INFO';

//Отображение списка (доступ на просмотр при редактировании раздела гида)
export const SHOW_ACCESS_LIST = 'SHOW_ACCESS_LIST';

//Удалить любую категорию гида
export const DELETE_GUIDE_CATEGORY = 'DELETE_GUIDE_CATEGORY';

//Получить список детей любого раздела
export const GET_GUIDE_CATEGORY_CHILDREN = 'GET_GUIDE_CATEGORY_CHILDREN';

//Очистить список детей раздела
export const CLEAR_GUIDE_CHILDREN_LIST = 'CLEAR_GUIDE_CHILDREN_LIST';

//Получить список всех групп ролей
export const GET_USERS_ROLES_LIST = 'GET_USERS_ROLES_LIST';

//Получить список статей категории
export const GET_CATEGORY_ARTICLES_LIST = 'GET_CATEGORY_ARTICLES_LIST';

//Очистить информацию об отдельной статье гида
export const CLEAR_GUIDE_ARTICLE_INFO = 'CLEAR_GUIDE_ARTICLE_INFO';

//Очистить список статей гида
export const CLEAR_GUIDE_ARTICLE_LIST = 'CLEAR_GUIDE_ARTICLE_LIST';

//Обновить информацию об отдельной статье гида
export const UPDATE_GUIDE_ARTICLE_INFO = 'UPDATE_GUIDE_ARTICLE_INFO';

//Перенести статью гида в архив
export const GUIDE_ARTICLE_TO_ARCHIVE = 'GUIDE_ARTICLE_TO_ARCHIVE';

export const SORT_ARTICLE_LIST = 'SORT_ARTICLE_LIST';

//Нормативные документы
export const SET_DOCUMENTS_LIST = 'SET_DOCUMENTS_LIST';
export const CLEAR_DOCUMENTS_LIST = 'CLEAR_DOCUMENTS_LIST';
export const SORT_DOCUMENT_LIST = 'SORT_DOCUMENT_LIST';

//Получить список связок роль-должности
export const GET_ROLE_BY_POSITION_LIST = 'GET_ROLE_BY_POSITION_LIST';

//Создать связку роль-должности
export const ADD_ROLE_BY_POSITION = 'ADD_ROLE_BY_POSITION';

//Обновить связку роль-должности
export const UPDATE_ROLE_BY_POSITION = 'UPDATE_ROLE_BY_POSITION';

//Удалить связку роль-должности
export const DELETE_ROLE_BY_POSITION = 'DELETE_ROLE_BY_POSITION';

//Получить полную информацию по текущему пользователю
export const GET_CUR_USER_FULL_INFO = 'GET_CUR_USER_FULL_INFO';

//Очистить информацию о пользователе
export const CLEAR_CUR_USER_FULL_INFO = 'CLEAR_CUR_USER_FULL_INFO';

//Получить список связок секретарь-подразделения
export const GET_SECRETARY_TO_DEPARTMENT_LIST = 'GET_SECRETARY_TO_DEPARTMENT_LIST';

//Создать связку секретарь-подразделения
export const ADD_SECRETARY_TO_DEPARTMENT = 'ADD_SECRETARY_TO_DEPARTMENT';

//Обновить связку секретарь-подразделения
export const UPDATE_SECRETARY_TO_DEPARTMENT = 'UPDATE_SECRETARY_TO_DEPARTMENT';

//Удалить связку секретарь-подразделения
export const DELETE_SECRETARY_TO_DEPARTMENT = 'DELETE_SECRETARY_TO_DEPARTMENT';

export const MONTHS = [
	'Январь',
	'Февраль',
	'Март',
	'Апрель',
	'Май',
	'Июнь',
	'Июль',
	'Август',
	'Сентябрь',
	'Октябрь',
	'Ноябрь',
	'Декабрь',
];

export const ZERO_THANKS_DATE = new Date(2019, 1, 1);

//Открыть / закрыть меню в шапке (админка!)
export const TOOGLE_ADMIN_MENU = 'TOOGLE_ADMIN_MENU';
export const CLOSE_ADMIN_MENU = 'CLOSE_ADMIN_MENU';

//Получить список всех категорий корневой категории нового справочника
export const GET_CONTACTS_CATEGORIES = 'GET_CONTACTS_CATEGORIES';

//Получить список корневых категорий нового справочника
export const GET_CONTACTS_TOP_CATEGORIES = 'GET_CONTACTS_TOP_CATEGORIES';

//Тоггл режима редактирования в справочнике
export const CONTACTS_EDIT_MODE = 'CONTACTS_EDIT_MODE';

//Получить список избранных контактов
export const GET_FAVORITE_CONTACTS_LIST = 'GET_FAVORITE_CONTACTS_LIST';

//Получить список id избранных контактов
export const GET_FAVORITE_CONTACTS_IDS_LIST = 'GET_FAVORITE_CONTACTS_IDS_LIST';

//Получить список недавних контактов
export const GET_RECENT_CONTACTS_LIST = 'GET_RECENT_CONTACTS_LIST';

//Получить информацию по категории
export const GET_CONTACTS_CATEGORY_INFO = 'GET_CONTACTS_CATEGORY_INFO';

//Очистить информацию по категории
export const CLEAR_CONTACTS_CATEGORY_INFO = 'CLEAR_CONTACTS_CATEGORY_INFO';

//Добавить служебную запись
export const ADD_ANOTHER_CONTACT = 'ADD_ANOTHER_CONTACT';

//Обновить служебную запись
export const UPDATE_ANOTHER_CONTACT = 'UPDATE_ANOTHER_CONTACT';

//Удалить служебную запись
export const DELETE_ANOTHER_CONTACT = 'DELETE_ANOTHER_CONTACT';

//Изменить сортировку контакта
export const CHANGE_CONTACTS_SORT = 'CHANGE_CONTACTS_SORT';

//Получить список корневых категорий ИТ-сервисов
export const GET_TOP_SERVICES_CATEGORIES = 'GET_TOP_SERVICES_CATEGORIES';

//Очистить список корневых категорий ИТ-сервисов
export const CLEAR_TOP_SERVICES_CATEGORIES = 'CLEAR_TOP_SERVICES_CATEGORIES';

//Новый список категорий в ИТ-сервисах
export const GET_CATEGORY_TREE_IT_SERVICES_NEW = 'GET_CATEGORY_TREE_IT_SERVICES_NEW';

//Получить список всех категорий ИТ-услуг
export const GET_ALL_CATEGORIES = 'GET_ALL_CATEGORIES';

//Добавить опрос
export const ADD_POLL = 'ADD_POLL';

//Оставить голос в опросе
export const UPDATE_POLL = 'UPDATE_POLL';

//Записать номер шага для черновика командировки
export const SET_TRIPS_ITEM_STEP = 'SET_TRIPS_ITEM_STEP';

//Записать номера шагов формы командировок, на которые нельзя перейти, пока не будут заполнены предыдущие
export const SET_TRIPS_DISABLED_STEPS = 'SET_TRIPS_DISABLED_STEPS';

//Установить список заявок на пропуска для админов
export const SET_ADMINS_PASSES_LIST = 'SET_ADMINS_PASSES_LIST';

//Получить список заявок на пропуска для админов
export const FETCH_ADMINS_PASSES_LIST = 'FETCH_ADMINS_PASSES_LIST';

//Тоггл загрузки списка заявок на пропуска для админов
export const FETCH_ADMINS_PASSES_LIST_SUCCESS = 'FETCH_ADMINS_PASSES_LIST_SUCCESS';

//Тоггл состояния последней страницы списка заявок на пропуска для админов
export const TOOGLE_LAST_PAGE_IN_ADMINS_PASSES_LIST = 'TOOGLE_LAST_PAGE_IN_ADMINS_PASSES_LIST';

//Очистить список заявок на пропуска для админов
export const CLEAR_ADMINS_PASSES_LIST = 'CLEAR_ADMINS_PASSES_LIST';

//Получить инфу по конкретной заявке на пропуск
export const SET_PASSES_INFO = 'SET_PASSES_INFO';

//Очистить инфу по конкретной заявке на пропуск
export const CLEAR_PASSES_INFO = 'CLEAR_PASSES_INFO';

//Изменить статус заявки на пропуск
export const CHANGE_PASSES_CLAIM_STATUS = 'CHANGE_PASSES_CLAIM_STATUS';

//Изменить сортировку
export const SORT_FAQ_LIST = 'SORT_FAQ_LIST';

//Получить инфу по категориям использующих FAQ
export const SET_CATEGORIES_LIST_FROM_FAQ = 'SET_CATEGORIES_LIST_FROM_FAQ';

//Очистить инфу по категориям использующих FAQ
export const CLEAR_CATEGORIES_LIST_FROM_FAQ = 'CLEAR_CATEGORIES_LIST_FROM_FAQ';

//Опция для записи ид Роздухова МЕ
export const SET_RME_ID = 'SET_RME_ID';

//Опция для записи ид раздела FAQ Помощь по порталу
export const SET_FAQ_HELP_ID = 'SET_FAQ_HELP_ID';

//Сохранение service workers
export const SET_REGISTRATION = 'SET_REGISTRATION';

//Получить данные фотоальбома в галерее
export const SET_ALBUM_DATA = 'SET_ALBUM_DATA';

//Обновить данные о фотоальбоме в галерее
export const UPDATE_ALBUM_DATA = 'UPDATE_ALBUM_DATA';

//Очистить данные о фотоальбоме в галерее
export const CLEAR_ALBUM_DATA = 'CLEAR_ALBUM_DATA';

//Получить список фотографий альбома в галерее
export const SET_PHOTOS_LIST = 'SET_PHOTOS_LIST';

//Очистить список фотографий альбома
export const CLEAR_PHOTOS_LIST = 'CLEAR_PHOTOS_LIST';

//Добавить фото в альбом в галерее
export const ADD_PHOTO_TO_LIST = 'ADD_PHOTO_TO_LIST';

//Удалить фото в альбом в галерее
export const REMOVE_PHOTO_FROM_LIST = 'REMOVE_PHOTO_FROM_LIST';

//Обновить фотографию в альбоме в галерее (добавить подпись)
export const UPDATE_PHOTO_IN_ALBUM = 'UPDATE_PHOTO_IN_ALBUM';

// Состояние модалки поздравления на главной
export const CONGRATULATION_MODAL_STATE = 'CONGRATULATION_MODAL_STATE';

//Состояние модалки на странице с заявкой "МаксиКлуб"
export const MAXICLUB_KPP_MODAL_STATE = 'MAXICLUB_KPP_MODAL_STATE';

//Информация о карте "МаксиКлуб"
export const MAXICLUB_KPP_INFO_CARD = 'MAXICLUB_KPP_INFO_CARD';

// Менторинг добавить список страниц
export const MENTORING_SET_LIST = 'MENTORING_SET_LIST';

// Менторинг добавить страницу в список
export const MENTORING_PUSH_TO_LIST = 'MENTORING_PUSH_TO_LIST';

// Менторинг удалить страницу
export const MENTORING_REMOVE_ITEM = 'MENTORING_REMOVE_ITEM';

// Менторинг обновить страницу
export const MENTORING_UPDATE_ITEM = 'MENTORING_UPDATE_ITEM';

// Добавить нового ментора в список
export const ADD_MENTOR_TO_LIST = 'ADD_MENTOR_TO_LIST';

// Добавить список менторов
export const MENTORS_SET_LIST = 'MENTORS_SET_LIST';

// Удалить ментора
export const DELETE_MENTOR = 'DELETE_MENTOR';

// Установить список биржи смен
export const SET_RETAIL_SHIFTS_LIST = 'SET_RETAIL_SHIFTS_LIST';
export const ADD_RETAIL_SHIFTS_LIST = 'ADD_RETAIL_SHIFTS_LIST';
// изменение статуса биржи смен
export const RETAIL_SHIFTS_CHANGE_STATUS = 'RETAIL_SHIFTS_CHANGE_STATUS';
export const RETAIL_SHIFTS_CLEAR_LIST = 'RETAIL_SHIFTS_CLEAR_LIST';

export const SET_APPLICATION = 'SET_APPLICATION';
export const SET_APPLICATIONS = 'SET_APPLICATIONS';

export const SET_CORPORATE_VALUES_LIST = 'SET_CORPORATE_VALUES_LIST';
export const ADD_CORPORATE_VALUES_LINK = 'ADD_CORPORATE_VALUES_LINK';
export const UPDATE_CORPORATE_VALUES_LINK = 'UPDATE_CORPORATE_VALUES_LINK';
export const DELETE_CORPORATE_VALUES_LINK = 'DELETE_CORPORATE_VALUES_LINK';

export const ADD_ISPRING_LINK = 'ADD_ISPRING_LINK';
export const ADD_MIRAPOLIS_LINK = 'ADD_MIRAPOLIS_LINK';

export const SET_ACHIEVEMENTS_LIST = 'SET_ACHIEVEMENTS_LIST';
export const ADD_ACHIEVEMENTS_ITEM = 'ADD_ACHIEVEMENTS_ITEM';
export const UPDATE_ACHIEVEMENTS_ITEM = 'UPDATE_ACHIEVEMENTS_ITEM';
export const SET_ARCHIVE_ACHIEVEMENTS_LIST = 'SET_ARCHIVE_ACHIEVEMENTS_LIST';

export const SET_LTR_LIST = 'SET_LTR_LIST';
export const ADD_LTR_LIST = 'ADD_LTR_LIST';
export const UPDATE_LTR_LIST = 'UPDATE_LTR_LIST';

export const SET_SURVEY = 'SET_SURVEY';
export const ADD_QUESTION = 'ADD_QUESTION';
export const SET_QUESTIONS = 'SET_QUESTIONS';
export const UPDATE_QUESTION_TITLE = 'UPDATE_QUESTION_TITLE';
export const ADD_QUESTION_ANSWER = 'ADD_QUESTION_ANSWER';
export const UPDATE_QUESTION_ANSWER = 'UPDATE_QUESTION_ANSWER';
export const DELETE_QUESTION_ANSWER = 'DELETE_QUESTION_ANSWER';
export const ADD_QUESTION_SCALE = 'ADD_QUESTION_SCALE';
export const DELETE_QUESTION = 'DELETE_QUESTION';
export const CHANGE_QUESTION_INDEX = 'CHANGE_QUESTION_INDEX';
export const ADD_QUESTION_CONDITION = 'ADD_QUESTION_CONDITION';
export const UPDATE_QUESTION_CONDITION_ID = 'UPDATE_QUESTION_CONDITION_ID';
export const UPDATE_QUESTION_CONDITION_VALUE = 'UPDATE_QUESTION_CONDITION_VALUE';
export const DELETE_QUESTION_CONDITION = 'DELETE_QUESTION_CONDITION';
export const DELETE_QUESTIONS = 'DELETE_QUESTIONS';
export const UPDATE_QUESTION_MIN = 'UPDATE_QUESTION_MIN';
export const UPDATE_QUESTION_MAX = 'UPDATE_QUESTION_MAX';
export const UPDATE_CONDITION_TYPE = 'UPDATE_CONDITION_TYPE';

export const UPDATE_SURVEY_TITLE = 'UPDATE_SURVEY_TITLE';
export const UPDATE_SURVEY_DESCRIPTION = 'UPDATE_SURVEY_DESCRIPTION';
export const UPDATE_SURVEY_START_DATE = 'UPDATE_SURVEY_START_DATE';
export const UPDATE_SURVEY_END_DATE = 'UPDATE_SURVEY_END_DATE';
export const UPDATE_SURVEY_USERS = 'UPDATE_SURVEY_USERS';
export const UPDATE_SURVEY_DEPARTMENS = 'UPDATE_SURVEY_DEPARTMENS';
export const UPDATE_SURVEY_PREVIEW = 'UPDATE_SURVEY_PREVIEW';
export const UPDATE_SURVEY_ANONYMOUS = 'UPDATE_SURVEY_ANONYMOUS';
export const CHANGE_QUESTION_TYPE = 'CHANGE_QUESTION_TYPE';
export const QUESTION_IMAGE = 'QUESTION_IMAGE';
export const CHANGE_ANSWER_OTHER_TYPE = 'CHANGE_ANSWER_OTHER_TYPE';
export const UPDATE_SURVEY_POSITIONS = 'UPDATE_SURVEY_POSITIONS';
export const COPY_SURVEY = 'COPY_SURVEY';
export const UPDATE_SURVEY_SPECIAL = 'UPDATE_SURVEY_SPECIAL';
export const UPDATE_SURVEY_FINISH_TEXT = 'UPDATE_SURVEY_FINISH_TEXT';
export const UPDATE_SURVEY_FINISH_BUTTON_NAME = 'UPDATE_SURVEY_FINISH_BUTTON_NAME';
export const UPDATE_SURVEY_FINISH_BUTTON_LINK = 'UPDATE_SURVEY_FINISH_BUTTON_LINK';

export const SET_WORKPLACE_CART_COUNT = 'SET_WORKPLACE_CART_COUNT';

export const SET_INVENTORY_LIST = 'SET_INVENTORY_LIST';
export const SET_ALL_INVENTORY_LIST = 'SET_ALL_INVENTORY_LIST';
export const UPDATE_INVENTORY_LIST = 'UPDATE_INVENTORY_LIST';
export const STEP_INVENTORY_FORM = 'STEP_INVENTORY_FORM';
export const ID_INVENTORY_LIST = 'ID_INVENTORY_LIST';
export const ADD_DOCUMENTS_INVENTORY = 'ADD_DOCUMENTS_INVENTORY';
export const DOCUMENTS_LIST_INVENTORY = 'DOCUMENTS_LIST_INVENTORY';
export const CLEAR_DOCUMENTS_LIST_INVENTORY = 'CLEAR_DOCUMENTS_LIST_INVENTORY';
export const SET_INVENTORY_CLAIM_LIST = 'SET_INVENTORY_CLAIM_LIST';
export const UPDATE_INVENTORY_CLAIM_LIST = 'UPDATE_INVENTORY_CLAIM_LIST';

export const EDUCATION_SET_ID = 'EDUCATION_SET_ID';
export const EDUCATION_SET_LIST = 'EDUCATION_SET_LIST';
export const EDUCATION_ADD_CATEGORY = 'EDUCATION_ADD_CATEGORY';
export const EDUCATION_UPDATE_CATEGORY = 'EDUCATION_UPDATE_CATEGORY';
export const EDUCATION_REMOVE_CATEGORY = 'EDUCATION_REMOVE_CATEGORY';
export const EDUCATION_ADD_CHILD_CATEGORY = 'EDUCATION_ADD_CHILD_CATEGORY';
export const EDUCATION_REMOVE_CHILD_CATEGORY = 'EDUCATION_REMOVE_CHILD_CATEGORY';
export const EDUCATION_UPDATE_CHILD_CATEGORY = 'EDUCATION_UPDATE_CHILD_CATEGORY';
export const MOVE_UP_EDUCATION_CATEGORY = 'MOVE_UP_EDUCATION_CATEGORY';
export const MOVE_DOWN_EDUCATION_CATEGORY = 'MOVE_DOWN_EDUCATION_CATEGORY';
export const MOVE_UP_EDUCATION_CHILD_CATEGORY = 'MOVE_UP_EDUCATION_CHILD_CATEGORY';
export const MOVE_DOWN_EDUCATION_CHILD_CATEGORY = 'MOVE_DOWN_EDUCATION_CHILD_CATEGORY';

export const SET_MESSENGER_CATEGORY_LIST = 'SET_MESSENGER_CATEGORY_LIST';
export const MESSENGER_CATEGORY_CHANGE_NAME = 'MESSENGER_CATEGORY_CHANGE_NAME';
export const MESSENGER_CATEGORY_CREATE = 'MESSENGER_CATEGORY_CREATE';
export const MESSENGER_CATEGORY_CHILDREN_CREATE = 'MESSENGER_CATEGORY_CHILDREN_CREATE';
export const MOVE_UP_MESSENGER_CATEGORY = 'MOVE_UP_MESSENGER_CATEGORY';
export const MOVE_DOWN_MESSENGER_CATEGORY = 'MOVE_DOWN_MESSENGER_CATEGORY';
export const DELETE_MESSENGER_CATEGORY = 'DELETE_MESSENGER_CATEGORY';
export const MESSANGES_COUNT = 'MESSANGES_COUNT';
export const MESSANGES_COUNT_DEC = 'MESSANGES_COUNT_DEC';

export const SET_STRATEGY_BLOCKS = 'SET_STRATEGY_BLOCKS';
export const ADD_STRATEGY_BLOCK = 'ADD_STRATEGY_BLOCK';
export const DELETE_STRATEGY_BLOCK = 'DELETE_STRATEGY_BLOCK';
export const SET_ACTIVE_BLOCK_STRATEGY = 'SET_ACTIVE_BLOCK_STRATEGY';
export const UPDATE_ACTIVE_BLOCK_IMAGE_STRATEGY = 'UPDATE_ACTIVE_BLOCK_IMAGE_STRATEGY';
export const UPDATE_ACTIVE_BLOCK_TEXT_STRATEGY = 'UPDATE_ACTIVE_BLOCK_TEXT_STRATEGY';
export const MOVE_UP_STRATEGY_CATEGORY = 'MOVE_UP_STRATEGY_CATEGORY';
export const MOVE_DOWN_STRATEGY_CATEGORY = 'MOVE_DOWN_STRATEGY_CATEGORY';
export const SET_STRATEGY_CATEGORIES = 'SET_STRATEGY_CATEGORIES';
export const ADD_STRATEGY_CATEGORIES = 'ADD_STRATEGY_CATEGORIES';
export const EDIT_STRATEGY_CATEGORIES = 'EDIT_STRATEGY_CATEGORIES';
export const DELETE_STRATEGY_CATEGORIES = 'DELETE_STRATEGY_CATEGORIES';

export const MAXI_PROFESSIONAL_AWARDS = 'MAXI_PROFESSIONAL_AWARDS';
export const MAXI_PROFESSIONAL_NO_ACTIVE = 'MAXI_PROFESSIONAL_NO_ACTIVE';

export const COURIER_COPY_CLAIM = 'COURIER_COPY_CLAIM';
export const VACATION_COPY_CLAIM = 'VACATION_COPY_CLAIM';
export const PASS_COPY_CLAIM = 'PASS_COPY_CLAIM';
export const STATEMENTS_COPY_CLAIM = 'STATEMENTS_COPY_CLAIM';

export const PULSE_SURVEY_UPDATE_START_DATE = 'PULSE_SURVEY_UPDATE_START_DATE';
export const PULSE_SURVEY_UPDATE_END_DATE = 'PULSE_SURVEY_UPDATE_END_DATE';
export const PULSE_SURVEY_UPDATE_ANONYMAUS = 'PULSE_SURVEY_UPDATE_ANONYMAUS';
export const PULSE_SURVEY_UPDATE_USERS = 'PULSE_SURVEY_UPDATE_USERS';
export const PULSE_SURVEY_UPDATE_DEPARTMENTS = 'PULSE_SURVEY_UPDATE_DEPARTMENTS';
export const PULSE_SURVEY_UPDATE_POSITION = 'PULSE_SURVEY_UPDATE_POSITION';
export const PULSE_SURVEY_UPDATE_QUESTION = 'PULSE_SURVEY_UPDATE_QUESTION';
export const PULSE_SURVEY_UPDATE_ANSWER_TYPE = 'PULSE_SURVEY_UPDATE_ANSWER_TYPE';
export const SET_PULSE_SURVEY = 'SET_PULSE_SURVEY';
export const CLEAR_PULSE_SURVEY = 'CLEAR_PULSE_SURVEY';

//Новые заявки
export const CLAIMS_PROFILE_GET_LIST = 'CLAIMS_PROFILE_GET_LIST';
export const CLAIMS_PROFILE_ADD_LIST = 'CLAIMS_PROFILE_ADD_LIST';
export const CLAIMS_PROFILE_CHANGE_STATUS = 'CLAIMS_PROFILE_CHANGE_STATUS';
export const CLAIMS_PROFILE_DELETE_CLAIM = 'CLAIMS_PROFILE_DELETE_CLAIM';

// Шар пожеланий
export const GET_LIST_WISHING_BALL = 'GET_LIST_WISHING_BALL';
export const ADD_PHRASE_WISHING_BALL = 'ADD_PHRASE_WISHING_BALL';
export const EDIT_PHRASE_WISHING_BALL = 'EDIT_PHRASE_WISHING_BALL';
export const DELETE_PHRASE_WISHING_BALL = 'DELETE_PHRASE_WISHING_BALL';

export const REPAIR_SERVICE_SET_CATEGORIES = 'REPAIR_SERVICE_SET_CATEGORIES';
